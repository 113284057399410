/**
 * WordPress dependencies
 */
import { SVG, Path } from '@wordpress/primitives';

const removeBug = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path d="M8.45474 21.2069L16.4547 3.7069L15.5453 3.29114L14.2837 6.05081C13.5991 5.69873 12.8228 5.49999 12 5.49999C10.9385 5.49999 9.95431 5.83076 9.1448 6.39485L7.18994 4.44L6.12928 5.50066L8.05556 7.42694C7.49044 8.15127 7.12047 9.0353 7.02469 9.99999H5V11.5H7V13H5V14.5H7.10002C7.35089 15.7359 8.0576 16.8062 9.03703 17.5279L7.54526 20.7911L8.45474 21.2069ZM9.68024 16.1209C8.95633 15.4796 8.5 14.5431 8.5 13.5V10.5C8.5 8.567 10.067 6.99999 12 6.99999C12.6003 6.99999 13.1653 7.15111 13.659 7.41738L9.68024 16.1209ZM15.3555 9.50155L16.1645 7.73191C16.6053 8.39383 16.8926 9.16683 16.9753 9.99999H19V11.5H17V13H19V14.5H16.9C16.4367 16.7822 14.419 18.5 12 18.5C11.7508 18.5 11.5058 18.4818 11.2664 18.4466L11.928 16.9993C11.9519 16.9998 11.9759 17 12 17C13.933 17 15.5 15.433 15.5 13.5V10.5C15.5 10.1531 15.4495 9.81794 15.3555 9.50155Z" />
	</SVG>
);

export default removeBug;
