/**
 * WordPress dependencies
 */
import { SVG, Path } from '@wordpress/primitives';

const cogAlt = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path
			clipRule="evenodd"
			fillRule="evenodd"
			d="M19.867 12c0-.568-.059-1.122-.17-1.656L21.5 8.732l-1.967-3.464-2.283.786a7.813 7.813 0 00-2.813-1.657L13.967 2h-3.934l-.472 2.396a7.813 7.813 0 00-2.81 1.657l-2.284-.785L2.5 8.732l1.804 1.612a8.054 8.054 0 000 3.312L2.5 15.268l1.967 3.464 2.283-.786a7.813 7.813 0 002.813 1.657l.47 2.397h3.934l.472-2.396a7.83 7.83 0 002.81-1.657l2.284.786 1.967-3.464-1.804-1.613c.111-.535.171-1.09.171-1.657V12zM12 16c-2.173 0-3.934-1.79-3.934-4S9.826 8 12 8c2.173 0 3.934 1.79 3.934 4s-1.76 4-3.934 4z"
		/>
	</SVG>
);

export default cogAlt;
