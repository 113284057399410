/**
 * WordPress dependencies
 */
import { Path, SVG } from '@wordpress/primitives';

const payment = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path
			fillRule="evenodd"
			d="M5.5 9.5v-2h13v2h-13zm0 3v4h13v-4h-13zM4 7a1 1 0 011-1h14a1 1 0 011 1v10a1 1 0 01-1 1H5a1 1 0 01-1-1V7z"
			clipRule="evenodd"
		/>
	</SVG>
);

export default payment;
